import _getUsedFilters from './getUsedFilters';

import {
  copyTextToClipBoard as copyText,
  splitEmailsList as splitEmails
} from './copyTextToClipBoard';
import { modules as moduleData, surveyTypesNew } from '../constants';

export const uppercaseSurvey = surveyType =>
  surveyType
    .split('-')
    .map(item => item[0].toLocaleUpperCase() + item.slice(1))
    .join(' ');

/**
 *  return the pre survey link
 * @param {String} shortId - session shortId
 */
export const getPreSurveyLink = ({ shortId }) => {
  let surveyURL = `${window.location.host}/survey/${shortId}/pre-course`;

  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 *  return the post survey link
 * @param {String} shortId - session shortId
 */

export const getPostSurveyLink = ({
  shortId,
  surveyType = surveyTypesNew.POST,
  course
}) => {
  let baseURL = `survey/${shortId}/${surveyType}`;

  if (surveyType === surveyTypesNew.FOLLOW_UP_3) {
    baseURL = `survey/${course}/${surveyType}`;
  }

  let surveyURL = `${window.location.host}/${baseURL}`;

  let url = `https://${surveyURL}`;

  if (process.env.NODE_ENV === 'development') {
    url = `http://${surveyURL}`;
  }

  return url;
};

/**
 * return array of survey links post & pre if exists
 * @param {Array} modules - session type ["1","2","3","special-2-days"...]
 * @param {String} shortId - session shortId
 */
export const getAllSurveyLinks = (modules, shortId) => {
  const includesTTT = modules?.includes(moduleData.TRAIN_THE_TRAINER);
  const includesTUS = modules?.includes(moduleData.TRAINER_UPSKILL);
  const includesModule1 = modules?.includes(moduleData.MODULE_1);
  if (includesTTT && !includesModule1) {
    return [
      getPostSurveyLink({
        shortId,
        surveyType: surveyTypesNew.POST_TRAIN_THE_TRAINER
      })
    ];
  }

  if (includesTTT && includesModule1) {
    return [
      getPreSurveyLink({ shortId }),
      getPostSurveyLink({
        shortId,
        surveyType: surveyTypesNew.POST_TRAIN_THE_TRAINER
      })
    ];
  }

  if (includesTUS && !includesModule1) {
    return [
      getPostSurveyLink({
        shortId,
        surveyType: surveyTypesNew.POST_TRAINER_UPSKILL
      })
    ];
  }

  if (includesTUS && includesModule1) {
    return [
      getPreSurveyLink({ shortId }),
      getPostSurveyLink({
        shortId,
        surveyType: surveyTypesNew.POST_TRAINER_UPSKILL
      })
    ];
  }

  if (includesModule1)
    return [getPreSurveyLink({ shortId }), getPostSurveyLink({ shortId })];
  return [getPostSurveyLink({ shortId })];
};

/**
 * return array of surveys for the session base on the survey type
 * @param {String} sessionType - session type ["1","2","3","special-2-days"...]
 */
export const getSessionSurveys = sessionType => {
  // return surveysTypes[sessionType];
};

export const validPostcode = postcode => {
  postcode.replace(/\s/g, '');
  const regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
  return regex.test(postcode);
};

export const handleEnterKey = (event, cb) => {
  event.preventDefault();
  return cb(event);
};

export const getUsedFilters = _getUsedFilters;
export const copyTextToClipBoard = copyText;
export const splitEmailsList = splitEmails;

export const convertAddressObjToString = address => {
  let fullAddress;
  if (address) {
    const { postcode, town, addressLine1, addressLine2 } = address;
    if (postcode || addressLine1 || addressLine2 || town) {
      fullAddress = [addressLine1, addressLine2, town, postcode]
        .filter(item => !!item)
        .join(', ');
    }
  }
  return fullAddress;
};
